<template>
    <div class="">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="caz-blocks-sarcho-title">
                <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'"> {{ $t("message.system_settings") }} </div>
                <div class="block-sarche">
                  <div class="header__search">
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12" class="flex-style text-right">
                <el-button :loading="loading" @click="submit()" class="btu-color" size="medium">{{ $t('message.save') }}</el-button>
            </el-col>
          </el-row>
        </div>
        <!-- end ifon-add-title-block -->
  
        <div v-loading="loading" class="card-table-header table-crm-smart custom__scroll">

            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item prop="parcel_min_shipping_weight" :label="$t('message.parcel_min_shipping_weight')">
                            <div class="d-flex">
                                <el-input @input="updateParcelWeight" :placeholder="$t('message.weight')" v-model="form.parcel_min_shipping_weight" class="mr-2">
                                    <el-button slot="append">{{ $t('message.kg') }}</el-button>
                                </el-input>
                                <el-input disabled v-model="viewForm.parcel_min_shipping_weight_gramm" class="mr-2">
                                    <el-button slot="append">{{ $t('message.gramm') }}</el-button>
                                </el-input>
                                <el-input disabled v-model="viewForm.parcel_min_shipping_weight_lbs">
                                    <el-button slot="append">{{ $t('message.lbs') }}</el-button>
                                </el-input>
                            </div>
                        </el-form-item>     
                    </el-col>

                    <el-col :span="12">
                        <el-form-item prop="commercial_min_shipping_weight" :label="$t('message.commercial_min_shipping_weight')">
                            <div class="d-flex">
                                <el-input @input="updateCommercialWeight" :placeholder="$t('message.weight')" v-model="form.commercial_min_shipping_weight" class="mr-2">
                                    <el-button slot="append">{{ $t('message.kg') }}</el-button>
                                </el-input>
                                <el-input disabled v-model="viewForm.commercial_min_shipping_weight_gramm" class="mr-2">
                                    <el-button slot="append">{{ $t('message.gramm') }}</el-button>
                                </el-input>
                                <el-input disabled v-model="viewForm.commercial_min_shipping_weight_lbs">
                                    <el-button slot="append">{{ $t('message.lbs') }}</el-button>
                                </el-input>
                            </div>
                        </el-form-item>     
                    </el-col>

                    <el-col :span="12">
                        <el-form-item prop="iin_countries" :label="$t('message.iin_countries')">
                            <select-country
                                :collapse_tags="false"
                                :multiple="true"
                                :size="'large'"
                                :placeholder="$t('message.iin_countries')"
                                :id="form.iin_countries"
                                v-model="form.iin_countries"
                            >
                            </select-country>
                        </el-form-item>     
                    </el-col>

                    <el-col :span="24">
                        <el-form-item prop="sender_filial_ids" :label="$t('message.sender_filial_ids')">
                            <select-filials
                                ref="Filials"
                                :collapse_tags="false"
                                v-model="form.sender_filial_ids"
                                :filial_ids="form.sender_filial_ids"
                                :size="'large'"
                                :placeholder="$t('message.sender_filial_ids')"
                            >
                            </select-filials>
                        </el-form-item>     
                    </el-col>

                    <el-col :span="24">
                        <el-form-item prop="current_order_filial" :label="$t('message.filial_order_ids')">
                            <select-filial
                                v-model="viewForm.current_order_filial"
                                :id="viewForm.current_order_filial"
                                @input="updateFilialOrder"
                                :size="'large'"
                                :placeholder="$t('message.sender_filial_ids')"
                            >
                            </select-filial>
                        </el-form-item>     
                        <div>
                            <span 
                                v-if="viewForm.filial_orders.length == filials.length"
                                class="font-bold p-2"
                            > 
                                Все филиалы добавлены !
                            </span>
                            <el-table
                              :data="viewForm.filial_orders"
                              style="width: 100%"
                              border
                            >
                                <el-table-column
                                    type="index"
                                    width="50">
                                </el-table-column>
                                <el-table-column
                                    prop="id"
                                    label="ID"
                                    width="180">
                                </el-table-column>
                                <el-table-column
                                    prop="name"
                                    :label="$t('message.filial')">
                                </el-table-column>
                                <el-table-column>
                                    <template slot="header" slot-scope="scope">
                                        <i class="el-icon-s-tools"></i>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-button
                                            @click="deleteRow(scope.$index)"
                                            type="text"
                                            size="small">
                                            {{ $t('message.delete') }}
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    // @ is an alias to /src
    import { mapGetters, mapActions } from "vuex";
    import selectCountry from "@/components/inventory/select-country";
    import selectFilial from "@/components/inventory/select-filial";
    import selectFilials from "@/components/multiSelects/multi-select-filials";
    
    export default {
        name: "Settings",
        components: {
            selectCountry,
            selectFilial,
            selectFilials
        },
    
        data() {
            return {
                form: {},
                loading: false,
                viewForm: {
                    current_order_filial: '',
                    filial_orders: [],
                    parcel_min_shipping_weight_lbs: 0,
                    parcel_min_shipping_weight_gramm: 0,
                    commercial_min_shipping_weight_lbs: 0,
                    commercial_min_shipping_weight_gramm: 0,
                },
            };
        },
        created(){
            this.loading = true;
            this.show()
                .then(res => {
                    setTimeout(() => {
                        this.loading = false;
                        this.form = JSON.parse(JSON.stringify(this.model));
                        this.updateParcelWeight((this.form.parcel_min_shipping_weight || 0));
                        this.updateCommercialWeight((this.form.commercial_min_shipping_weight || 0));
                        this.viewForm.filial_orders = this.filials.filter(el => this.model.filial_order_ids.includes(el.id));
                    }, 700);
                })
                .catch(err => {
                    this.loading = false;
                })
        },
    
        computed: {
            ...mapGetters({
                filials: "filials/userFilials",
                rules: "systemSettings/rules",
                model: "systemSettings/model",
                mode: "MODE"
            }),
        },
        methods: {
            ...mapActions({
                show: "systemSettings/show",
                save: "systemSettings/update",
            }),
            submit(){
                // this.notifyChrome();
                this.form.filial_order_ids = this.viewForm.filial_orders.map(el => el.id);
                this.loading = true;
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.save(this.form)
                        .then(res => {
                            this.loading = false;
                            this.$alert(res);
                        })
                        .catch(err => {
                            this.loading = false;
                            this.$alert(err);
                        })
                    }
                });
            },
            notifyChrome(){
                if (Notification.permission === 'granted') {
                        const notification = new Notification('Как дела брат!', {
                        body: 'Как идут дела у Havvoex )))',
                        actions: [
                            { action: 'confirm', title: 'Confirm' },
                            { action: 'cancel', title: 'Cancel' }
                        ]
                    });

                    notification.onclick = function () {
                        // Handle the notification click event
                        console.log('Handle the notification click event!');
                    };

                    notification.addEventListener('action', function (event) {
                        if (event.action === 'confirm') {
                            // Handle the "Confirm" button click
                            console.log('User clicked Confirm');
                        } else if (event.action === 'cancel') {
                            // Handle the "Cancel" button click
                            console.log('User clicked Cancel');
                        }
                    });

                } else if (Notification.permission !== 'denied') {
                    Notification.requestPermission().then(permission => {
                        if (permission === 'granted') {
                            document.getElementById('showNotification').click();
                        }
                    });
                }
            },
            updateParcelWeight(val){
                this.$set(this.viewForm, 'parcel_min_shipping_weight_lbs', _.round((this.$kg_to_pound * val), 3));
                this.$set(this.viewForm, 'parcel_min_shipping_weight_gramm', 1000 * val);
            },
            updateCommercialWeight(val){
                this.$set(this.viewForm, 'commercial_min_shipping_weight_lbs', _.round((this.$kg_to_pound * val), 3));
                this.$set(this.viewForm, 'commercial_min_shipping_weight_gramm', 1000 * val);
            },
            updateFilialOrder(val){
                if(val && !this.viewForm.filial_orders.map(el => el.id).includes(parseFloat(val))){
                    let filial = this.filials.find(el => el.id == val);
                    this.viewForm.filial_orders.push(filial);
                }
            },
            deleteRow(index){
                this.viewForm.filial_orders.splice(index, 1);
            }

        },
        beforeRouteLeave (to, from, next) {
            this.$store.commit('sources/EMPTY_LIST');
            next()
        },
    };
</script>
  
  